import type { ResponsiveComponent } from '@src/interfaces';
import React, {TouchEvent, useContext, useEffect, useState} from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import { TeaserSliderComponent } from '@src/interfaces';
import TeaserComponent from '@src/components/TeaserComponent';
import StackedTeaserCarousel from '@src/components/StackedTeaserCarousel';
import ComponentTitle from '@src/components/ComponentTitle';
import SvgImage from '@src/components/SvgImage';
import toggleBodyClass from '@src/utils/toggle-body-class';
import BREAKPOINTS from '@src/utils/breakpoints';
import getClassName from '@src/utils/get-class-name';
import { trackEvent } from '@src/utils/et';
import trackPageImpression from '@src/utils/oewa';
import {Models} from "@data/et-web-api";
import onClickVideoTeaser from "@src/hooks/onClickVideoTeaser";
import onClickTeaser from "@src/hooks/onClickTeaser";
import RouteContext from "@src/components/Layout/route-context";
import styles from './styles.module.scss';

interface Breakpoint {
  breakpoint: number;
  settings: {
    slidesToShow: number;
  };
}

export interface CustomSliderRef {
  props: {
    responsive: Breakpoint[];
    slidesToShow: number;
  };
  state: Breakpoint;
  innerSlider: {
    state: {
      currentSlide: number;
      slideCount: number;
    };
  };

  slickNext(): void;

  slickPause(): void;

  slickPlay(): void;

  slickPrev(): void;
}

const getSlidesPerSize = (size: string) => {
  switch (size) {
    case 'small':
      return 4;
    case 'medium':
      return 3;
    case 'large':
      return 2;
    default:
      return 2;
  }
};

const reduceCount = (slideCount: number, subtrahend: number | boolean) => {
  let tempSubtrahend = subtrahend;

  if (typeof tempSubtrahend === 'boolean') {
    tempSubtrahend = tempSubtrahend ? 0 : 1;
  }

  return slideCount - tempSubtrahend < 1 ? 1 : slideCount - tempSubtrahend;
};

const getItemPerPage = (sliderRef: CustomSliderRef | undefined) => {
  if (!sliderRef) {
    return 1;
  }

  const { props, state } = sliderRef;
  const breakpoint = state?.breakpoint;

  if (breakpoint) {
    return (
      props.responsive.find((item: Breakpoint) => item.breakpoint === breakpoint)?.settings
        .slidesToShow ?? 1
    );
  }
  return props.slidesToShow;
};

const getSliderPosition = (sliderRef: CustomSliderRef) => {
  const { innerSlider } = sliderRef;
  const itemPerPage = getItemPerPage(sliderRef);

  const { currentSlide, slideCount } = innerSlider.state;

  const page = Math.ceil((currentSlide + 1) / itemPerPage);
  const count = Math.ceil(slideCount / itemPerPage);

  return `${page} of ${count}`;
};

const getSliderSettings = (isFullWidth: boolean, size: string, rows: number) => {
  const teaserPerPage = reduceCount(getSlidesPerSize(size), isFullWidth);

  return {
    dots: false,
    infinite: true,
    speed: 125,
    slidesToScroll: teaserPerPage,
    slidesToShow: teaserPerPage,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: BREAKPOINTS.desktop,
        settings: {
          slidesToShow: reduceCount(teaserPerPage, 1),
          slidesToScroll: reduceCount(teaserPerPage, 1),
        },
      },
      {
        breakpoint: BREAKPOINTS.tablet,
        settings: {
          slidesToShow: reduceCount(teaserPerPage, 2),
          slidesToScroll: reduceCount(teaserPerPage, 2),
          rows,
        },
      },
      {
        breakpoint: BREAKPOINTS.smallMobile,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows,
        },
      },
    ],
  };
};

type TeaserSliderProps = TeaserSliderComponent & ResponsiveComponent;

const TeaserSliderCore = ({
  id,
  className,
  teasers,
  title,
  mobileRows = 1,
  size = 'medium',
  regionWidth,
  link,
}: TeaserSliderProps) => {
  const isFullWidth = regionWidth === '12';
  const [pageIndicator, setPageIndicator] = useState('');
  const [sliderRef, setSliderRef] = useState<CustomSliderRef | undefined>(undefined);
  const [allTeasersShown, setAllTeasersShown] = useState<boolean>(false);
  const route = useContext(RouteContext);

  useEffect(() => {
    const updateAllTeasersShown = () => {
      setAllTeasersShown(
        getItemPerPage(sliderRef) >= teasers.length
      )
    };
    updateAllTeasersShown();
    window.addEventListener('resize', updateAllTeasersShown)
    return () => window.removeEventListener('resize', updateAllTeasersShown)
  }, [sliderRef, teasers.length]);

  if (!teasers.length) return null;

  let startX = 0;

  const swipeAction = (event: TouchEvent<HTMLDivElement>) => {
    const { type } = event;
    const { screenX } = event.changedTouches[0];
    const threshold = 20;

    if (type === 'touchstart') {
      startX = screenX;
    } else if (type === 'touchmove') {
      if (screenX > startX + threshold || screenX < startX - threshold) {
        // moved more than 20px left or right
        toggleBodyClass(true);
      }
    } else if (type === 'touchend') {
      toggleBodyClass(false);
      startX = 0;
    }
  };

  const updateSliderProps = () => {
    if (sliderRef) {
      setPageIndicator(getSliderPosition(sliderRef));
    }
  };

  const sliderSettings = getSliderSettings(isFullWidth, size, mobileRows);

  const trackingEvent = () => {
    const eventName: Models.LaneScrollClick['eventName'] = 'Lane Scroll Click'
    const eventPayload: Models.LaneScrollClick['eventPayload'] = {
      laneName: title || 'Not available',
      lanePosition: teasers[0].lanePosition || '',
      numberOfTeasers: Number(sliderRef?.innerSlider.state.slideCount),
    }
    trackEvent(eventName, eventPayload);
  }

  const onClickPrev = () => {
    if (sliderRef) sliderRef.slickPrev();
    trackPageImpression(route.meta.coralContext);
    trackingEvent();
  };

  const onClickNext = () => {
    if (sliderRef) sliderRef.slickNext();
    trackPageImpression(route.meta.coralContext);
    trackingEvent();
  };
  return (
    <div
      className={getClassName([
        'teaser-slider',
        [!!className, '', `${className}`, ''],
        `slides-per-page-${getItemPerPage(sliderRef)}`,
        [!!title, '', 'has-title', 'no-title'],
      ])}
      data-vr-zone={`TeaserSlider ${title ?? id}`}
    >
      {title ? <h3 className="component-title teaser-list-title">
        <ComponentTitle link={link} skipWrapper title={title} />
      </h3> : null}

      <div aria-live="polite" className="sr-only">
        {`Page ${pageIndicator}`}
      </div>

      <div
        className="teaser-collection"
        onTouchEnd={swipeAction}
        onTouchMove={swipeAction}
        onTouchStart={swipeAction}
      >
        {!allTeasersShown &&
          <button
            aria-label="Vorherige"
            className="controlPrev"
            onClick={onClickPrev}
            tabIndex={0}
            type="button"
          >
            <SvgImage reference="chevron-left" title="Vorherige" />
          </button>
        }
        <Slider
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(c: any) => {
            setSliderRef(c);
            updateSliderProps();
          }}
          {...sliderSettings}
          afterChange={() => {
            updateSliderProps();
          }}
        >
          {teasers ? teasers.map((teaser, index) => (
            <div className="teaser-list-slide">
              <TeaserComponent
                key={index}
                contentBox={`TeaserSlider Box #${index + 1}`}
                onClick={() => teaser.videoDuration ? onClickVideoTeaser(teaser, title, teasers.length, index) : onClickTeaser(teaser, title, teasers.length, index)}
                size='medium'
                {...teaser}
              />
            </div>
          )) : null}
        </Slider>
        {!allTeasersShown &&
          <button
            aria-label="Nächste"
            className="controlNext"
            onClick={onClickNext}
            tabIndex={0}
            type="button"
          >
            <SvgImage reference="chevron-right" title="Nächste" />
          </button>
        }
      </div>
    </div>
  );
};

const TeaserSlider = (props: TeaserSliderProps) => (
  <>
    <div className={styles.teaserSliderDesktop}>
      <LazyLoad height="100%" once>
        <TeaserSliderCore {...props} />
      </LazyLoad>
    </div>
    <div className={styles.teaserSliderMobile}>
      <LazyLoad height="100%" once>
        <StackedTeaserCarousel
          link={props.link}
          teasers={props.teasers}
          title={props.title}
        />
      </LazyLoad>
    </div>
  </>
);

export default TeaserSlider;
