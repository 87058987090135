import type { FC } from 'react';
import type { Teaser } from '@src/interfaces';
import type { TeaserSize } from '@src/components/Teaser/Teaser';
import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import SvgImage from '@src/components/SvgImage';
import CustomImage from '@src/components/CustomImage';
import styles from './styles.module.scss';

type TeaserComponentProps = Teaser & {
  contentBox?: string;
  className?: string;
  disablesFocus?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  size?: TeaserSize;
}

const TeaserComponent: FC<TeaserComponentProps> = ({
  title,
  url,
  videoDuration,
  image,
  category,
  contentBox,
  className,
  disablesFocus,
  onClick,
  live,
  liveTag,
  size = 'large',
}) => {
  const vrContentbox = contentBox ? {
    'data-vr-contentbox': contentBox,
    'data-vr-contentbox-url': url,
  } : {};
  const vrHeadline = contentBox ? { 'data-vr-headline': '' } : {};

  return (
    <div className={classnames(
      styles.container,
      { [className as string]: !!className },
    )}>
      <Link as={stripUrlPlaceholder(url)} href="/">
        <a
          className={styles.anchor}
          onClick={onClick}
          {...(disablesFocus ? { tabIndex: -1 } : {})}
          {...vrContentbox}
        >
          <div className={styles.illustration}>
            {image ? <CustomImage {...image} size={size} /> : null}

            {videoDuration ? <SvgImage
              className={styles.play}
              reference="play-circle"
              title="Video"
              /> : null}

            {live ? <div className={styles.livetag}><span>{liveTag}</span></div> : null}
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.content}>
              {category ? <div className={styles.category}>{category.title}</div> : null}
              <h3
                className={styles.title}
                {...vrHeadline}
              >
                {title}
              </h3>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default TeaserComponent;
